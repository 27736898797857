import { useContext } from "react";
import { ResponsiveContext } from "../../ResponsiveContext.js";
import Chart from "../main/Chart";
import {
  STATUS_COLOR_BAR_BORDER,
  formatDateHHMM,
  formatDateDDMMYYYY,
  fromDate,
} from "../../Utils";
import { ResponsiveContainer, BarChart, Bar, Tooltip } from "recharts";
import { Typography } from "@mui/material/";
import StatusLegend from "../main/StatusLegend";
import "./UptimeChart.scss";

type UptimeChartType = {
  metric: any;
};

type ContextProp = {
  isLargeScreen: boolean;
  isMediumScreen: boolean;
  isSmallScreen: boolean;
  isExtraSmallScreen: boolean;
  l: any; // Replace 'any' with the actual type of 'l' if possible
};

const PERCENT_TO_FAIL = 0.9; // Amount to fail
const AMOUNT_TO_SHOW = 100;
const AMOUNT_TO_SHOW_MOBILE = 8;

const UptimeChart = ({ metric }: UptimeChartType) => {
  const { points, desc, label, metricName, channelName } = metric;
  const contextValue = useContext(ResponsiveContext);
  const { isExtraSmallScreen } = contextValue as ContextProp;

  if (!points.length) return;

  const pointsToShow = points
    .slice(isExtraSmallScreen ? -AMOUNT_TO_SHOW_MOBILE : -AMOUNT_TO_SHOW)
    .map((point: any) => ({
      ...point,
      // iso: formatDateHHMM(point.iso),
      // Add 'one' as key for Bar configuration.
      one: 1,
    }));

  // TO TEST!!!
  // Rondomly generate the amount of 'ok'
  // pointsToShow.forEach((point: any) => {
  //   point.value = Math.random() < 0.9 ? 1 : 0;
  // });

  const okAmount = pointsToShow.filter((obj: any) => obj.value === 1).length;
  const percentAmount = ((okAmount / pointsToShow.length) * 100).toFixed(2);
  const okStatus =
    okAmount === pointsToShow.length
      ? 0
      : okAmount >= PERCENT_TO_FAIL * pointsToShow.length
      ? 1
      : 2;

  const additionalInfo = (
    <span style={{ textWrap: "nowrap" }}>Uptime: {percentAmount}%</span>
  );

  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const realPayload = payload[0].payload;
      const isoDate = realPayload.iso;
      return (
        <div className="binaryBarTooltip">
          <StatusLegend status={realPayload.value === 1 ? 0 : 2} />
          <div className="date">
            <span>{formatDateDDMMYYYY(isoDate)} - </span>
            {formatDateHHMM(isoDate)}hs
          </div>
        </div>
      );
    }

    return null;
  };

  const BarWithBorder = (props: any) => {
    const { x, y, width, height, payload } = props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke="none"
          fill={STATUS_COLOR_BAR_BORDER[payload.value === 1 ? 0 : 2]}
        />
      </g>
    );
  };

  // if (metricName === "api-get-chats") return null;

  return (
    <Chart
      title={label}
      subTitle={desc}
      errorState={okStatus}
      additionalInfo={additionalInfo}
      channelName={channelName}
      metricName={metricName}
    >
      <span className="uptime-chart-container">
        <ResponsiveContainer className="container" height="100%">
          <BarChart data={pointsToShow} barCategoryGap={"10%"}>
            <Tooltip content={<CustomTooltip />} cursor={false} />
            <Bar dataKey="one" shape={<BarWithBorder />} />
          </BarChart>
        </ResponsiveContainer>
        <div className="custom-x-axis-binary">
          <Typography>{fromDate(pointsToShow[0].iso)}</Typography>
          <Typography>
            {fromDate(pointsToShow[pointsToShow.length - 1].iso)}
          </Typography>
        </div>
      </span>
    </Chart>
  );
};

export default UptimeChart;
