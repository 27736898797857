import { useEffect, useMemo, useState } from "react";
// import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";
// import Paper from "@mui/material/Paper";
// import LinearProgress from "@mui/material/LinearProgress";
// import Button from "@mui/material/Button";
// import StatusCard from './StatusCard';
// import OtherMetrics from "./OtherMetrics";
// import Channel from "./Channel";
// import Uptime from "./components/Uptime";
import { getLang, l } from "./Lang";
import { ResponsiveContext } from "./ResponsiveContext";
import {
  REFRESH_TIME,
  getAuth,
  getMetrics,
  getHistoryLogs,
  formatDateDDMMYYYHHSS,
  changeMomentLocale,
} from "./Utils";
// import ChannelWebchat from "./components/ChannelWebchat";
import MonthlyUptime from "./components/MonthlyUptime";
import Header from "./components/main/Header.tsx";
import Footer from "./components/main/Footer.tsx";
import ChartContainer from "./components/main/ChartContainer.tsx";
import "./_global.scss";
// import Chart from "./components/main/Chart.tsx";
import ContentHeader from "./components/main/ContentHeader.tsx";
import StatusLegend from "./components/main/StatusLegend.tsx";
// import { default as OldUptimeChart } from "./components/oldCharts/UptimeChart.js";
import UptimeChart from "./components/charts/UptimeChart";

// import MainPage from "./components/main/MainPage.tsx";
import HistoryPage from "./components/history/HistoryPage";
import LogsPage from "./components/logs/LogsPage";
// import UptimeLineChart from "./components/charts/UptimeLineChart";
import UptimeBarChart from "./components/charts/UptimeBarChart";

// const { DateTime } = require("luxon");

const PAGES = {
    MAIN: "MAIN",
    HISTORY: "HISTORY",
    LOGS: "LOGS",
};

const getJwtPayload = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};

const App = () => {
    const [businessName, setBusinessName] = useState("");
    const [metrics, setMetrics] = useState({});
    const [headerPageTitle, setHeaderPageTitle] = useState(
        l(`page_${PAGES.MAIN}`)
    );
    const [selectedPage, setSelectedPage] = useState(PAGES.MAIN);
    const [profileLogged, setProfileLogged] = useState();
    const [languageSelected, setLanguageSelected] = useState(
        localStorage.getItem("language") || "es-ES"
    );
    const [historyData, setHistoryData] = useState();
    const [logsData, setLogsData] = useState();

    const useMedia = (query) => {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) setMatches(media.matches);

            const listener = () => setMatches(media.matches);

            media.addListener(listener);

            return () => media.removeListener(listener);
        }, [matches, query]);

        return matches;
    };

    const allCookies = document.cookie.split(";");
    const getCookie = (name) => {
        const cookie = allCookies.find((c) => c.includes(name));
        return cookie ? cookie.split("=")[1] : null;
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        // If running LOCALHOST, it won't be able to read "freshToken" from cookie of go.botmaker.com... add it manually here.
    const authToken = getCookie("freshToken") || null;
        const desiredBid = urlSearchParams.get("bid") || null;

        let decodedJWT = undefined;

        try {
            if (authToken) decodedJWT = getJwtPayload(authToken);
            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (!authToken || currentTimestamp > decodedJWT.exp)
                throw new Error(
                    `ERROR validating authToken. ${
                        !authToken ? "No token found." : "Token expired"
                    }`
                );
        } catch (e) {
            console.error(e.stack, e);
      window.location = "https://go.botmaker.com/?status_mon=true";
            return;
        }

        setProfileLogged(decodedJWT);
        let interval;

        changeMomentLocale();

        getAuth(authToken, desiredBid, getLang())
            .then((raw) => {
                if (raw.data.redirect) {
                    window.location = raw.data.redirect;
                    return;
                }

                const businessId = raw.data.businessId;

                setBusinessName(raw.data.name);

                const getData = () => {
                    getMetrics(businessId, getLang(), raw.data.authToken || null).then(
                        (metrics) => {
                            setMetrics(metrics);
                        }
                    );
                    getHistoryLogs(businessId, raw.data.authToken || null).then(
                        (historyLogs) => {
                            setHistoryData(historyLogs.History);
                            setLogsData(historyLogs.ChangeLog);
                        }
                    );
                };

                getData();
                interval = setInterval(() => {
                    getData();
                }, REFRESH_TIME);
            })
            .catch((err) => console.error(err.stack, err));

        return () => {
            clearInterval(interval);
        };
    }, []);

    const isLargeScreen = useMedia("(min-width: 800px)");
    const isMediumScreen = useMedia("(max-width: 799px) and (min-width: 650px)");
    const isSmallScreen = useMedia("(max-width: 649px) and (min-width: 450px)");
    const isExtraSmallScreen = useMedia("(max-width: 449px)");

    // const channels = metrics?.channels || [];
    // const loading = Object.keys(metrics).length === 0;
    // const monitorActive = loading || channels.length > 0;
    // const refreshTime = !metrics?.refreshTime
    //   ? null
    //   : DateTime.fromISO(metrics?.refreshTime);

    const metricIsWebchat = (metric) =>
        (metric.metricName && metric.metricName.includes("webchat")) ||
        (metric.subType && metric.subType.includes("webchat")) ||
        (metric.label && metric.label.includes("webchat"));

    // const noMetrics =
    //   !metrics ||
    //   ((!metrics.channelMetrics || metrics.channelMetrics.length === 0) &&
    //     (!metrics.uptime || metrics.uptime.length === 0) &&
    //     (!metrics.othersValues || metrics.othersValues.length === 0));

    // Mock errors
    // if (metrics) {
    //   metrics.uptime?.forEach((element) => {
    //     element.points.forEach((point) => {
    //       point.v = Math.random() < 0.95 ? 1 : 0;
    //     });
    //   });
    // }
    // console.log("metrics: ", metrics);

  // const noMetrics =
  //   !metrics ||
  //   ((!metrics.channelMetrics || metrics.channelMetrics.length === 0) &&
  //     (!metrics.uptime || metrics.uptime.length === 0) &&
  //     (!metrics.othersValues || metrics.othersValues.length === 0));

  // Mock errors
  // if (metrics) {
  //   metrics.uptime?.forEach((element) => {
  //     element.points.forEach((point) => {
  //       point.v = Math.random() < 0.95 ? 1 : 0;
  //     });
  //   });
  // }
  // console.log("metrics: ", metrics);

  const webchatsById = useMemo(() => {
    const webchats = metrics?.channels?.filter(
      (channel) => channel.platform === "webchat"
    );
    if (!webchats) return {};
    return webchats.reduce((acc, webchat) => {
      const webchatId = webchat.id.includes("-webchat-null-")
        ? webchat.id.substring(webchat.id.indexOf("-webchat-null-") + "-webchat-null-".length)
        : webchat.id;
      acc[webchatId] = webchat;
      return acc;
    }, {});
  }, [metrics]);

  const uptimeMetrics = (metrics?.uptime || []).filter(
    (metric) => !metricIsWebchat(metric) && !metric.metricName.endsWith("_status_checker")
  );

  const channelsUptimeMetrics = (metrics?.uptime || []).filter(
    (metric) => metric.metricName.endsWith("_status_checker")
  ).map((metric) => {
    const channelName = webchatsById[metric.subType]?.channelName ?? metric.subType;
    return {
      ...metric,
      channelName
    }
  });

  const { totalPoints, totalErrors, systemsWithErrors } = uptimeMetrics.reduce(
        (acc, element) => {
            const totalPoints = acc.totalPoints + element.points.length;
            const amountOfErrors = element.points.filter(
                (point) => point.v === 0
            ).length;

            if (amountOfErrors > 0) {
                acc.totalErrors += amountOfErrors;
                acc.systemsWithErrors.push(element.label); // TODO How to get real System name?
            }

      return { ...acc, totalPoints };
        },
    { totalPoints: 0, totalErrors: 0, systemsWithErrors: [] }
    );

    const getMetricsStatus = () => {
        // if any maintenance is not ok, return instability
        const anyHistoryNotOk = (historyData || []).filter(data => data.STATUS !== 'OK').length;
        if (anyHistoryNotOk)
            return 1;

        if (totalPoints === 0) return undefined;

        const percentage = totalErrors / totalPoints;
        return percentage === 0 ? 0 : percentage > 0.1 ? 2 : 1;
    };

    const handlePageChange = (page) => {
        setSelectedPage(page);
        setHeaderPageTitle(l(`page_${page}`));
    };

    return (
        <ResponsiveContext.Provider
            value={{
                isLargeScreen,
                isMediumScreen,
                isSmallScreen,
                isExtraSmallScreen,
                l,
            }}
        >
            <Header
                pageTitle={headerPageTitle}
                businessName={businessName}
                onPageChange={(page) => handlePageChange(page)}
                profileLogged={profileLogged}
                languageSelected={languageSelected}
                setLanguageSelected={setLanguageSelected}
                showHistory={historyData && historyData.length > 0}
                showLogs={logsData && logsData.length > 0}
            />
            <div className="scroll-box">
                <Container className="main-page-container">
                    {/* {selectedPage === PAGES.MAIN && <MainPage />} */}
                    {selectedPage === PAGES.HISTORY && (
                        <HistoryPage
                            entries={historyData}
                            onBack={() => handlePageChange(PAGES.MAIN)}
                        />
                    )}
                    {selectedPage === PAGES.LOGS && (
                        <LogsPage
                            logs={logsData}
                            onBack={() => handlePageChange(PAGES.MAIN)}
                        />
                    )}
                    {selectedPage === PAGES.MAIN && (
                        <>
                            <ContentHeader
                                status={getMetricsStatus()}
                                lastUpdate={formatDateDDMMYYYHHSS(metrics.refreshTime)}
                                subStatus={systemsWithErrors}
                            />

              <StatusLegend />

                            <ChartContainer title={l("charts_title_platforms_states")}>
                                {uptimeMetrics.map((metric, idx) => {
                                    return metric.metricType === "boolean" ? (
                    <UptimeChart key={idx} metric={metric} />
                                    ) : (
                    <UptimeBarChart key={idx} metric={metric} />
                  );
                })}
              </ChartContainer>
            </>
          )}
          {channelsUptimeMetrics?.length > 0 && (
            <ChartContainer title={l("charts_title_channels_states")}>
              {channelsUptimeMetrics.map((metric, idx) => {
                return metric.metricType === "boolean" ? (
                  <UptimeChart key={idx} metric={metric} />
                ) : (
                  <UptimeBarChart key={idx} metric={metric} />
                );
              })}
            </ChartContainer>
          )}
          {/* <Paper className="main-container" elevation={3} hidden>
            <div className="header-old">
              <h1>{l("title") + (businessName ? ` - ${businessName}` : "")}</h1>
              {refreshTime && (
                <p style={{ fontSize: "0.8em" }}>
                  {l("lastUpdated")}{" "}
                  {refreshTime.toLocaleString(DateTime.DATE_MED) +
                    " " +
                    refreshTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}
                </p>
              )}
            </div>

            {loading && <LinearProgress />}

            {!loading && noMetrics && (
              <div className="no-metrics">
                <h3>{l("noMetrics")}</h3>
              </div>
            )}

            {!monitorActive && (
              <div className="no-monitor bold">
                <span>{l("noMonitor")}</span>

                <div className="no-monitor-button">
                  <Button
                    onClick={(_) => {
                      window.open(
                        `https://help.botmaker.com/${getLang()}/support`,
                        "_blank"
                      );
                    }}
                    variant="text"
                  >
                    Botmaker Help
                  </Button>
                </div>
              </div>
            )}

            {monitorActive && (
            <div className="report-container">
                {loading &&
                  [1, 2, 3].map((i) => (
                    <Skeleton
                      key={i}
                      className="skeleton"
                      variant="rectangular"
                      width={"100%"}
                      height={45}
                    />
                  ))}

              {(metrics?.channelMetrics || []).map((channel, idx) => (
                <Channel key={"_" + idx} channel={channel} />
              ))}

              {(metrics?.channels || [])
                .filter(
                  (channel) =>
                    channel.platform === "webchat" &&
                    metrics?.uptime.some(
                      (metric) => metric.subType === channel.id
                    )
                )
                .map((channel, idx) => (
                  <ChannelWebchat
                    key={"_" + idx}
                    channel={channel}
                    uptime={metrics?.uptime.find(
                      (data) => data.subType === channel.id
                    )}
                  />
                ))}

              {(metrics?.uptime || [])
                .filter((metric) => !metricIsWebchat(metric))
                .map((uptimeMetric, idx) => (
                  <Uptime key={"_" + idx} metric={uptimeMetric} />
                ))}

              {(metrics?.othersSpec || []).map((v, idx) => (
                <OtherMetrics
                  key={"i_" + idx}
                  metrics={metrics}
                  metricSpec={v}
                />
              ))}
            </div>
            )}
          </Paper> */}

                    {metrics.monthlyUptime && selectedPage === PAGES.MAIN && (
            <MonthlyUptime data={metrics.monthlyUptime} />
                    )}
                </Container>
        <Footer />
      </div>
    </ResponsiveContext.Provider >
  );
};

export default App;
